$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$blue-light: #00aeef !default;
$blue: #01205e !default;
$blue-dark: #001f5f !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red-light: #a02842 !default;
$red: #9f2841 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #8DC63F !default;
$teal: #20c997 !default;
$cyan: #03aeef !default;
$white: #fff !default;
$black-p: #414042 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue-light': $blue-light,
    'blue': $blue,
    'blue-dark': $blue-dark,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red-light': $red-light,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'black-p': $black-p,
    'gray': $gray-600,
    'gray-dark': $gray-800
  ),
  $colors
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark
  ),
  $theme-colors
);

$enable-hover-media-query: false !default;
$site-content-padding-vertical: 8px;
$navbar-height: 72px;
$page-title-height: 56px;
$side-nav-width: 250px;
$default-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.1);
$bottom-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.1);
$transparent-subtle: rgba(0, 113, 206, 0.05);
$default-border: 1px solid $gray-400;
$mobile-nav-width: 260px;
$font-size-base: 14px;

@import "variables";

.multi-select {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  transition: 200ms;

  &.selected {
    color: $white;
    background-color: $primary;
  }

  &.selected.editable {
    &:hover {
      cursor: pointer;
      background-color: darken($primary, 15%);
    }
  }

  &.editable{
    cursor: pointer;
    &:hover {
      background-color: lighten($primary, 15%);
      color: #fff;
    }
  }

}

@import "variables";

$cardShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

@media screen and (max-width: 612px) {
  .card-deck {
    width: 100%;
  }

  app-lpl-card {
    width: 100%;
  }

  .lpl-card {
    min-height: 210px;
    width: 100%;
  }
}

@media screen and (min-width: 613px) and (max-width: 904px) {
  .lpl-card {
    min-height: 210px;
    width: 90vw;
  }
}


@media screen and (min-width: 905px) {
  .lpl-card {
    min-height: 210px;
    width: 30vw;
    max-width: 550px;
  }
}

.card-deck {
  align-items: center;
}

.lpl-card {
  margin-bottom: 1rem !important;
  & h1, h2, h3, h4, h5, h6 {
    color: $blue-dark;
    font-size: 1.5em;
    font-weight: 100;
    font-family: 'Roboto Slab', serif;
    clear: both;
    letter-spacing: .1em;
    line-height: 1.2em;
    margin: 0 0 0.5em 0;
    text-transform: uppercase;
  }
  a {
    letter-spacing: .1em;
    text-decoration: none;
    color: inherit;
  }
  .on-behalf-of-link {
    color: $blue-dark !important;
    cursor: pointer;
  }

  a.button {
    background: none;
    border-bottom: 2px solid $red-light;
    color: $blue-dark;
    display: inline-block;
    font-weight: 700;
    line-height: 1.5em;
    padding: .75em 0 .25em 0;
    text-transform: uppercase;
  }

  a.button:hover {
    color: $blue-dark;
    text-decoration: none;
    border-bottom: 2px solid $blue-dark;
  }

  button.button {
    background: none;
    border: none;
    border-bottom: 2px solid $red-light;
    color: $blue-dark;
    display: inline-block;
    font-weight: 700;
    line-height: 1.5em;
    padding: .75em 0 .25em 0;
    text-transform: uppercase;
  }

  button.button:hover {
    color: $blue-dark;
    text-decoration: none;
    border-bottom: 2px solid $blue-dark;
  }

  p {
    color: $black-p;
    margin-bottom: 0px;
  }

  p.edit {
    color: $blue-dark;
  }

  p.view {
    color: $red-light;
  }

  .card-footer > * {
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 576px) {
  .lpl-form-card {
    height: 210px;
    width: 430px;
  }
}

.lpl-form-card {
  margin-bottom: 1rem !important;
  & h1, h2, h3, h4, h5, h6 {
    color: $blue-dark;
    font-size: 1.5em;
    font-weight: 100;
    font-family: 'Roboto Slab', serif;
    clear: both;
    letter-spacing: .1em;
    line-height: 1.2em;
    margin: 0 0 0.5em 0;
    text-transform: uppercase;
  }
  a {
    letter-spacing: .1em;
    text-decoration: none;
    color: inherit;
  }

  a.button {
    background: none;
    border-bottom: 2px solid $red-light;
    color: $blue-dark;
    display: inline-block;
    font-weight: 700;
    line-height: 1.5em;
    padding: .75em 0 .25em 0;
    text-transform: uppercase;
  }

  a.button:hover {
    color: $blue-dark;
    text-decoration: none;
    border-bottom: 2px solid $blue-dark;
  }

  button.button {
    background: none;
    border-bottom: 2px solid $red-light;
    color: $blue-dark;
    display: inline-block;
    font-weight: 700;
    line-height: 1.5em;
    padding: .75em 0 .25em 0;
    text-transform: uppercase;
  }

  button.button:hover {
    color: $blue-dark;
    text-decoration: none;
    border-bottom: 2px solid $blue-dark;
  }

  p {
    color: $black-p;
    margin-bottom: 0px;
    text-overflow: ellipsis;
  }

  p.edit {
    color: $blue-dark;
  }

  p.view {
    color: $red-light;
  }
}


@import "variables";

$font-color: $dark;

.version {
  color: $gray-600;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.mobile-nav {
  position: fixed;
  background: linear-gradient(135deg, #002b57 25%,#004e75 75%);
  top: 0;
  height: 100vh;
  z-index: 1040;
  width: $mobile-nav-width;
  color: $white;
  left: -$mobile-nav-width;
  font-size: 15px;
  transition: ease-in-out 150ms;
  padding: 10px 15px;

  &.open {
    left: 0;
  }

  & .brand {
    margin: 20px 10px 10px;
    text-align: center;
  }

  & .phone-link {
    color: $white;
    text-decoration: none;
    background-color: #001746;
    font-size: 1em;
    display: block;
    line-height: 1.5em;
    padding: 8px 15px;
    text-align: center;
    margin-top: 30px;
    border-radius: 3px;
  }

  & menu {
    margin: 40px 0;
    & li {
      & a {
        color: $white;
        border-radius: 3px;
        text-transform: uppercase;
        font-size: 1em;
        display: block;
        line-height: 1.5em;
        padding: 10px 15px;
        text-decoration: none;

        &:hover {
          text-decoration: none;
          background-color: #001746;
        }
      }
    }
  }
}

.site-nav {
  position: fixed;
  height: 100%;
  width: $side-nav-width;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $gray-800;
  color: $gray-400;

  & footer {
    position: absolute;
    bottom: 4px;
    right: 12px;
    z-index: 9000;
  }
}

@media(max-width: 1024px)  {
  .site-nav {
    position: relative;
    width: 100%;
  }
}

.side-nav, .sub-nav {
  list-style: none;
  margin: 1px 0 0 0;
  padding: 0 0 0 0;
  .side-nav-item .side-nav-link {
    padding: 15px;
  }
  fa-icon.ng-fa-icon {
    width: 38px;
  }
}

.sub-nav {
  background-color: lighten($dark, 3%);
  .sub-nav {
    .side-nav-link {
      padding-left: 20px;
    }
  }

  & .side-nav-link {
    padding-left: 48px;
    font-size: 0.8rem;

    &:hover {
      color: lighten($danger, 10%);
      text-decoration: none;
    }

    & .fa {
      margin-right: 4px;
    }
  }
}

.side-nav-item {
  background-color: transparent;
  color: $white;
}

.side-nav-dropdown {
  background-color: lighten($dark, 3%);

  &.collapsed {
    background-color: $dark;

    & .chevron:before {
      top: 0em;
      transform: rotate(-135deg);
    }
  }
}

.chevron {
  position: absolute;
  right: 18px;
  top: 16px;
}

.chevron::before {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0;
  transform: rotate(-225deg);
  vertical-align: top;
  width: 0.45em;
  transition: 250ms;
}

.side-nav-link,
.side-nav-dropdown {
  position: relative;
  display: block;
  padding: 9px 30px 9px 9px;
  font-size: 15px;
  font-weight: 300;
  color: $white;
  text-decoration: none;

  & .link-text {
    display: block;
    position: relative;
    transition: 200ms ease-out;
  }

  &.active {
    background-color: darken($secondary, 3%);
    color: $white;

    &:hover {
      background-color: lighten($secondary, 3%);
      color: $white;

      & .link-text {
        transform: translateX(6px);
      }
    }
  }

  &:hover {
    background-color: $gray-700;
    color: $white;
    text-decoration: none;

    & .link-text {
      transform: translateX(6px);
    }
  }

  & .fa {
    position: relative;
    top: -2px;
    vertical-align: middle;
    font-size: 1.4rem;
    display: inline-block;
    margin-right: 8px;
  }
}

$alertSize: 8px;

.pop {
  position: relative;
  display: inline-block;
  height: $alertSize;
  width: $alertSize;
  z-index: 5000;
  border-radius: 50%;
  top: -5px;
  left: -5px;

  &.pop-danger {
    background-color: $danger;
  }
}
.hide {
  display: none;
}

@import "variables";

.form-control, .btn {
  border-radius: 0 !important;
}

.btn {
  font-size: $font-size-base;
}

.overflow-visible{
  overflow: visible !important;
}

@import "variables";

$fab-size: 2.5rem;

.btn-fab,
.btn-fab-img  {
  display: flex;
  min-width: 0;
  border-radius: 50%;
  padding: 0;
  width: $fab-size;
  height: $fab-size;
  flex-shrink: 0;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: none;
  &:focus {
    outline: none !important;
  }


}

.btn-fab {
  position: relative;
  z-index: 800;
  &:hover {
    background-color: rgba(255,255,255, .3);
  }

  & .fab-pill {
    position: absolute;
    z-index: 500;
    font-size: 0.8rem;
    top: 0;
    right: 0;
  }

  & .fab-icon, .fab-icon-md, .fab-icon-lg {
    background-color: transparent;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    position: relative;
  }

  & .fab-icon {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  & .fab-icon-md {
    font-size: 1.7rem !important;
    line-height: 1.7rem !important;
  }

  & .fab-icon-lg {
    font-size: 2.0rem !important;
    line-height: 2.0rem !important;
  }

  &.btn-pop-danger {
    &:after {
      content: '';
      background-color: $danger;
      height: 9px;
      width: 9px;
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 50%;
    }
  }
}

@each $color, $value in $theme-colors {
  .fab-#{$color} {
    background-color: $value;

    &:hover {
      background-color: darken($value, 7%);
    }
  }
}

.fab-lg{
  width: $fab-size * 1.3;
  height: $fab-size * 1.3;
}

.fab-sm{
  width: calc($fab-size / 1.3);
  height: calc($fab-size / 1.3);
}

.fab-xs{
  width: calc($fab-size / 1.5);
  height: calc($fab-size / 1.5);

  & .fab-icon {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}

img.btn-fab-img,
.btn-fab-img {
  img {
    width: $fab-size;
    height: $fab-size;
    border-radius: 50%;
  }
}

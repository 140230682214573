@import 'ngx-toastr/toastr';
@import "variables.scss";
@import "spinner/spinner.scss";
@import "mixins/background.scss";
@import "mixins/toggle.scss";
@import "fab.scss";
@import "layout.scss";
@import "side-nav.scss";
@import "global-nav.scss";
@import "bs-overrides.scss";
@import "multi-select.scss";
@import "lpl-card.scss";
@import "form-wizard.scss";

@import "formiojs/dist/formio.form.min.css";
@import 'bootstrap/dist/css/bootstrap.css';


@import "primeng/resources/themes/nova/theme.css";
@import 'primeng/resources/primeng.css';

#zipcode-input {
  width: 100%;
}

.new-option-card {
  width: 500px;
  display: block;
}

.new-option-card:hover {
  cursor: pointer;

  .p-card {
    background-color: lightgrey;
  }
}

.disabled-card {
  width: 500px;
  display: block;

  .p-card {
    background-color: lightgrey;
    color: dimgrey;
  }
}

body {
  font-family: 'proxima-nova',sans-serif;
  font-size: $font-size-base;
  background-color: #f1f2f2;
}

a {
  color: #001f5f;
}

h1, h2, h3, h4, h5, h6 {
  color: $blue-dark;
  font-weight: 100;
  font-family: 'Roboto Slab', serif;
  text-transform: uppercase;
  margin: 0 0 0 0;
}

span.card-title {
  color: $blue-dark;
  font-weight: 100;
  font-family: 'Roboto Slab', serif;
  text-transform: uppercase;
  margin: 0 0 0 0;
  font-size: 1.5rem;
}

.card {
  box-shadow: $cardShadow;
}

.app-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #FFF;
  margin: -8px -15px 20px -15px;
  padding: 20px 30px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

@media print {
  .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .app-title {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.app-title h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
}

.app-title p {
  margin-bottom: 0;
  font-style: italic;
}

@media (max-width: 480px) {
  .app-title p {
    display: none;
  }
}

.app-breadcrumb {
  margin-bottom: 0;
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: left;
  padding: 0;
  background-color: transparent;
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-top: 10px;
  }
}

.form-control:focus {
  border: 4px solid lightblue;
  box-shadow: none;
}

.clearfix {
  clear: both;
}

.tile {
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  padding: 20px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media print {
  .tile {
    border: 1px solid #ddd;
  }
}

.tile.small {
  padding: 0;
}

.tile .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #555;
  border-radius: 3px;
}

.tile .overlay .l-text {
  font-size: 24px;
  margin-top: 15px;
}

.tile .tile-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.tile .tile-title-w-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.tile .tile-title-w-btn p {
  margin: 0;
}

.tile .tile-title-w-btn .title {
  margin: 0;
}

.tile .tile-title-w-btn .side {
  margin-bottom: 0;
  padding-left: 0;
}

.tile .tile-title-w-btn .btn-group {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tile .tile-footer {
  border-top: 1px solid #ddd;
  padding: 20px 0 0 0;
  margin-top: 10px;
}

.tile {
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  padding: 20px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ng-autocomplete-dropdown {
  position: relative;
}
.ng-autocomplete-dropdown .ng-autocomplete-inputs {
  position: relative;
}
.ng-autocomplete-dropdown .ng-autocomplete-inputs input {
  width: 100%;
  padding: 6px 15px;
  font-family: Arial;
  font-weight: normal;
  outline: none !important;
  font-size: 15px;
  height: 39px;
  border: 1px solid #ced4da;
}

.ng-autocomplete-dropdown .ng-autocomplete-placeholder {
  position: absolute;
  margin: 3px;
  background-color: #fff;
  padding: 8px 18px;
  font-family: Arial;
  font-weight: normal;
  font-size: 15px;
  width: calc(100% - 4px);
}
.ng-autocomplete-dropdown .ng-dropdown {
  display: none;
  border: 1px solid #e0e0e0;
  z-index: 99999;
  max-height: 280px;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
}
.ng-autocomplete-dropdown .ng-dropdown.open {
  display: block;
}
.ng-autocomplete-dropdown .ng-dropdown .dropdown-item {
  width: 100%;
  cursor: pointer;
  padding: 8px 20px;
  font-family: Arial;
  font-weight: normal;
  font-size: 15px;
  height: 39px;
  background-color: #ffffff;
}

.ng-autocomplete-dropdown .ng-dropdown .dropdown-item.active {
  background-color: #0099cc;
  color: #fff !important;
}

.ng-autocomplete-dropdown .ng-dropdown .dropdown-item .dropdown-item-highlight {
  font-weight: bold;
}

.ng-autocomplete-dropdown-icon {
  display: block;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 999;
  font-size: 12px;
  color: #758694;
  padding: 21px 0;
}

.ng-autocomplete-dropdown-icon:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid #000;
  position: absolute;
  right: 5px;
  z-index: 999;
  top: 16px;
}

.ng-autocomplete-dropdown-icon.open:after {
  transform: rotate(180deg);
}

.separator {
  width: 100%;
  border-top: 1px solid #dfdfdf;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.modal {
  color: #212529;
}

.form-group {
  >label:not(.form-check-label) {
    font-weight: bold;
  }
}

.custom-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.black-overlay {
  opacity: 0.5;
}

.audit-container {
  max-height: 250px;
  overflow-y: auto;
}

.has-lookup {
  color: $red;
}

.border-3 {
  border: 3px solid;
}

.circle {
  margin: 0 0 5px 0;
  content: ' \25CF';
  font-size: 20px;
  color: $red;
}

.profile-data {
  label {
    font-weight: bold;
  }
  >span {
    margin-left: 10px;
  }
  >input {
    margin-left: 10px;
  }
}

.caret-off::before {
  display: none;
}

.caret-off::after {
  display: none;
}

select {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.fa-remove:before {
  content: "\f00d"; }

.fa-cloud-upload:before {
  content: "\f382";
}

.blink {
  opacity: 0;
  animation: 1s blink infinite;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@import "variables";
@import "collapse-nav";

$profile-width: 300px;
$menu-user-icon: 3.2rem;

.navbar {
  height: $navbar-height;
  padding: 0 0 0 1rem;
  box-shadow: 0 0 9px 0 rgba(0,0,0,0.3);

  & .nav-link {
    font-size: 0.8rem;
    color: $blue !important;
  }
}

.navbar-dark {
  background-color: $gray-700 !important;
}

.navbar-green {
  background-color: green !important;
}

.navbar-light {
  background-color: #ffffff !important;
}

.brand {
  &:hover {
    text-decoration: none !important;
  }

  & .brand-text {
    color: $primary;
    text-decoration: none;
    position: relative;
  }
}

.user-icon{
  background-color: $gray-600;
  color: $gray-700;
  height: $menu-user-icon;
  width: $menu-user-icon;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  text-align: center;
  padding: 2px;
}

.profile-menu {
  position: absolute;
  background-color: #fff;
  width: $profile-width;
  z-index: 900;
  right: 0;
  transition: 200ms;
  opacity: 0;
  box-shadow: 3px 5px 2px 0 rgba(0,0,0,0.3);
  display: none;

  &.open {
    opacity: 1;
    display: block;
  }

  & .button-row {
    background-color: $gray-100;
  }

  .menu-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 2 auto;
    width: calc(#{$profile-width} - #{$menu-user-icon} - 3.5rem) ;
  }
}

.sign-out-btn {
  background-color: $danger;
  color: $white !important;
  cursor: pointer;
  text-align: center;
  transition: 200ms;
  &:hover {
    background-color: darken($danger, 10%);
  }
}

.profile-btn {
  color: $primary;
  height: $navbar-height;
  text-align: right;
  cursor: pointer;
  transition: 200ms;
  padding: .49rem 1rem;

  .profile-user-name{
    padding-top: 3px;
  }

  &:hover {
    background-color: $primary;
    color: #fff;
  }

  &.active {
    background-color: $primary;
    color: #fff;
  }

  & .rolename {
    text-transform: uppercase;
    font-size: 10px;
  }

  & .user-icon{
    background-color: $gray-600;
    color: $gray-700;
    height: $menu-user-icon;
    width: $menu-user-icon;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    flex-shrink: 0;
    align-items: center;
    text-align: center;
  }
}

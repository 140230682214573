@import "variables";

.collapse-menu {
  background-color: $primary;
  border-radius: 3px;
  background-clip: padding-box;
  margin: 0 0 0 0 !important;

  &.burger.open {
    & span {
      background: transparent !important;
      &::before {
        transform: rotate(45deg) translate(5px, 6px);
        width: 20px !important;
      }
      &::after {
        transform: rotate(-45deg) translate(5px, -6px);
        width: 20px !important;
      }
    }
  }

  &.burger {
    cursor: pointer;
    position: relative;
    height: 3em;
    width: 3em;

    & span {
      background: #fff;
      display: block;
      height: 1px;
      left: 50%;
      position: absolute;
      top: 50%;
      transition: all .2s ease-in-out;
      transform: translate(-50%, -50%);
      width: 20px;

      &::before {
        background: #fff;
        content: '';
        height: 1px;
        position: absolute;
        transition: all .2s ease-in-out;
        top: -8px;
        width: 18px;
      }
      &::after {
        background: #fff;
        content: '';
        height: 1px;
        position: absolute;
        transition: all .2s ease-in-out;
        top: 8px;
        width: 18px;
      }
    }
  }
}

@import "variables";

.form-io-form {
  & > div {
    margin-bottom: 62px;
  }

  & formio-alerts .alert {
    margin-left: $side-nav-width;
  }

  & .formio-component-submit {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    padding: 10px;
    margin: 0;
    box-shadow: 0 0 9px 0 rgba(0,0,0,0.3);
    display: flex;
    justify-content: flex-end;

    & .btn-primary {
      background-color: $primary;
      border-color: $primary;

      &:disabled {
        opacity: .20;
        box-shadow: none;
      }
      &::after {
        content: none!important;
      }

    }
  }
}

.no-alerts {
  formio-alerts .alert {
    display: none !important;
  }
}

.formio-form {

  & > div {
    margin-top: 0;
  }

  .list-inline {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    padding: 10px;
    margin: 0 0 0 $side-nav-width;
    box-shadow: 0 0 9px 0 rgba(0,0,0,0.3);
    display: flex;
    justify-content: flex-end;

    & .btn-primary {
      background-color: $primary;
      border-color: $primary;
    }
  }

  & formio-alerts .alert {
    margin-left: $side-nav-width;
  }

  & .wizard-page {
    margin-left: $side-nav-width;
    position: relative;
    background-color: $white;
    background-clip: border-box;
    border: 1px solid rgba(#000000, .125);
    flex: 1 1 auto;
    padding: 1.25rem;
    margin-bottom: 62px;
  }

  & .btn-wizard-nav-cancel {
    display: none;
  }

  & .form-group .formio-form {
    margin-left: 0;
    padding: 0;
    margin-bottom: 0;
    border: none;
  }

  nav {
    &::before {
      content: '';
      position: fixed;
      top: $navbar-height;
      left: 0;
      height: calc(100vh - #{$navbar-height});
      width: $side-nav-width;
      z-index: 9999;
    }

    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    top: $navbar-height;
    left: 0;
    width: $side-nav-width;
    height: calc(100vh - #{$navbar-height});
    background-color: $blue;
    color: #fff !important;
    padding-top: 20px;

    & ul {
      display: block;
    }

    .page-item.active .page-link {
      background-color: darken($primary, 8%) !important;
    }

    & ul > li {
      & .page-link {
        font-weight: lighter;
        display: block;
        color: darken(#ffffff, 20%) !important;
        padding: 10px 23px;
        background-color: $primary;
        transition: ease-in-out 150ms;
        cursor: default !important;
        border: none;
        width: 100%;
        text-align: left;
        * {
          color: darken(#ffffff, 20%) !important;
        }
      }
    }
  }

  @media(max-width: 1024px)  {
    .list-inline {
      margin: 0 0 0 0;
    }

    & formio-alerts .alert {
      margin-left: 0;
    }
    nav {
      &::before {
        left: -$side-nav-width;
      }
      left: -$side-nav-width;
    }
    & .formio-form, .wizard-page {
      margin-left: 0;
    }
  }
}

.form-nav {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: $navbar-height;
  left: 0;
  width: $side-nav-width;
  height: calc(100vh - #{$navbar-height});
  background-color: $blue;
  color: #fff !important;
  padding-top: 20px;

  & ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .page-item.active .page-link {
    background-color: darken($primary, 8%);
  }

  & ul > li {
    & .page-link {
      font-weight: lighter;
      display: block;
      color: darken(#ffffff, 20%);
      padding: 10px 23px;
      background-color: $primary;
      transition: ease-in-out 150ms;
      cursor: default !important;
      border: none;
    }
  }
}

.form-status {
  position: fixed;
  bottom: 0;
  margin-left: $side-nav-width;
  padding: 16px 0;
  color: $gray-500;

  @media(max-width: 1024px) {
    margin-left: 0;
  }
}

.wizard-container {
  margin-left: $side-nav-width;
  padding: 0 15px;
}

.wizard-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  padding: 10px;
  margin: 0 0 0 $side-nav-width;
  box-shadow: 0 0 9px 0 rgba(0,0,0,0.3);
}

// TODO: package @angular/formio 7.0.0 has a bug with Formio "html components" where it renders the component twice
// this is a workaround to hide the duplicate component in the ui
[ref="component"] {
  p[ref="html"] {
    display: none;
  }
}

@import "variables";

.site-content {
  position: relative;
  margin-top: 52px;
}

.with-side-nav {
  margin-left: #{$side-nav-width};
}

@media(max-width: 1024px)  {
  .site-content {
    margin-left: 0;
  }
}
